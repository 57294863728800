import img1 from "./assets/gallery/gallery1.jpg";
import img3 from "./assets/gallery/gallery15.jpg";
import img4 from "./assets/gallery/gallery4.jpg";
import img5 from "./assets/gallery/gallery5.jpg";
import img6 from "./assets/gallery/gallery6.jpg";
import img7 from "./assets/gallery/gallery7.jpg";
import img8 from "./assets/gallery/gallery8.jpg";
import img9 from "./assets/gallery/gallery9.jpg";
import img10 from "./assets/gallery/gallery10.jpg";
import img12 from "./assets/gallery/gallery12.jpg";
import img13 from "./assets/gallery/gallery13.jpg";
import img14 from "./assets/gallery/gallery14.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashPhotos = [
  { id: img14, width: 400, height: 267 },

  { id: img4, width: 300, height: 450 },
  { id: img1, width: 400, height: 600 },

  { id: img10, width: 300, height: 450 },
  { id: img7, width: 300, height: 450 },

  { id: img6, width: 300, height: 412 },
  { id: img9, width: 300, height: 450 },

  { id: img3, width: 400, height: 267 },

  { id: img5, width: 300, height: 450 },
  { id: img13, width: 300, height: 450 },

  { id: img12, width: 300, height: 450 },
  { id: img8, width: 300, height: 450 },
];

const photos = unsplashPhotos.map((photo) => ({
  src: photo.id,
  width: photo.width,
  height: photo.height,
  images: breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      src: photo.id,
      width: breakpoint,
      height,
    };
  }),
}));

export default photos;
