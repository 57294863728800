import axios from "axios";
import { useEffect, useState } from "react";
import imageMain from "./assets/main_photo.jpg";
import imageMainLandscape from "./assets/main_landscape.jpg";
import rsvp from "./rsvp-calligraphy.png";
import loadingImg from "./loading.gif";
import bg from "./bg.jpg";
import bgPhone from "./bg-phone.jpg";
import "./main.css";
import { useMediaQuery } from "react-responsive";
import PhotoAlbum from "react-photo-album";
import photos from "./photos";
import { scrollIntoView } from "seamless-scroll-polyfill";
import "react-slideshow-image/dist/styles.css";

import song from "./assets/song.mp3";
import outerVinyl from "./assets/vinyl-1.png";
import innerVinyl from "./assets/vinyl-2.png";
import classNames from "classnames";

import mailGif from "./assets/firstload.gif";
import mailIcon from "./assets/mail-48.png";
import mapMarker from "./assets/map-marker.png";
import calendar from "./assets/calendar.png";

import mask from "./assets/mask.png";
import soap from "./assets/soap.png";
import distance from "./assets/distance.png";
import pedulilindungi from "./assets/pedulilindungi.png";

var audio;

export default function MainPage() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [streamingOnly, setStreamingOnly] = useState(null);
  const [attendance, setAttendance] = useState(null);
  const [noOfGuest, setNoOfGuest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [failedSubmission, setFailedSubmission] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [inviteeDetail, setInviteeDetail] = useState(null);
  const [isInvited, setIsInvited] = useState(false);
  const [dueStr, setDueStr] = useState("");
  const [showNoOfGuest, setShowNoOfGuest] = useState(true);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // console.log("init", loading);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1180px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1180px)" });

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    audio = new Audio(song);
    audio.volume = 0.2;

    //COUNTDOWN
    var countDownDate = new Date("Jan 14, 2023 00:00:00").getTime();

    setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);
    const pathName = window.location.pathname;

    axios
      .post("https://livrncnypozlhcydlwad.functions.supabase.co/invitee", {
        id: pathName.substring(1),
      })
      .then(
        (res) => {
          setInviteeDetail(res.data);
          setIsInvited(true);
          const dueDate = new Date(res.data.due_date);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          setDueStr(
            `${dueDate.getDate()} ${
              months[dueDate.getMonth()]
            } ${dueDate.getFullYear()}`
          );

          dueDate.setHours(23);
          dueDate.setMinutes(59);
          dueDate.setSeconds(59);

          console.log("date", dueDate);

          const tomorrow = new Date(dueDate);
          tomorrow.setDate(tomorrow.getDate() + 1);
          const streaming =
            res.data.streaming_only ||
            (Date.now() > dueDate && res.data.rsvp == null);
          // console.log(Date.now() > dueDate);
          setStreamingOnly(streaming);

          if (res.data.rsvp != null) setShowNoOfGuest(false);
        },
        (err) => {
          console.error("ERROR", err);
          setStreamingOnly(true);
          setFirstLoad(false);
        }
      );
  }, []);

  useEffect(() => {
    if (inviteeDetail) {
      setFirstLoad(false);
      if (inviteeDetail.rsvp !== null) setShowNoOfGuest(false);
    }
    // console.log("inv d", inviteeDetail);
  }, [inviteeDetail]);

  const generateSeatOption = () => {
    if (inviteeDetail) {
      let opt = [];
      for (let i = 0; i < inviteeDetail.seat; i++) {
        opt.push(
          <option value={i + 1} key={i + 1}>
            {i + 1}
          </option>
        );
      }
      return opt;
    }
    return null;
  };

  useEffect(() => {
    if (audio) {
      if (audioPlay) audio.play();
      else audio.pause();
    }
  }, [audioPlay]);

  const openInvClick = () => {
    if (!isOpen) {
      document.body.style.overflowY = "unset";

      setAudioPlay(true);
      audio.play();
      setIsOpen(true);
    }
    scrollIntoView(document.getElementById("content"), {
      behavior: "smooth",
      block: "nearest",
    });
  };

  const onAudioControlClick = () => {
    if (audioPlay) setAudioPlay(false);
    else setAudioPlay(true);
  };

  const openChurchMap = () => {
    window.open("https://goo.gl/maps/MTPfBNw42BYTAMXT9");
  };

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxpdnJuY255cG96bGhjeWRsd2FkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjgzNjk1MDYsImV4cCI6MTk4Mzk0NTUwNn0.Jes6dGES-z_iP15CcN7vCI8tbG7Sy4nhgWPMUM4-SQw`;

  const openHotelMap = () => {
    window.open(
      "https://www.google.com/maps/place/JHL+Solitaire+Gading+Serpong/@-6.2414145,106.6584709,17z/data=!4m8!3m7!1s0x2e69fc7a72f513bf:0x3c4348d03219e0dd!5m2!4m1!1i2!8m2!3d-6.2555703!4d106.6204226"
    );
  };

  const onAttendanceChange = (e) => {
    setAttendance(e.target.value);
    if (e.target.value == "no") setShowNoOfGuest(false);
    else setShowNoOfGuest(true);
  };

  const onNoOfGuestChange = (e) => {
    setNoOfGuest(e.target.value);
  };

  const onSubmitClick = () => {
    if (!attendance || (attendance && attendance == "yes" && !noOfGuest)) {
      setFormErr(true);
    } else {
      setFormErr(false);
      setFailedSubmission(false);
      setLoading(true);
      submitData();
    }
  };

  const refreshData = () => {
    const pathName = window.location.pathname;

    axios
      .post("https://livrncnypozlhcydlwad.functions.supabase.co/invitee", {
        id: pathName.substring(1),
      })
      .then(
        (res) => {
          setInviteeDetail(res.data);
        },
        (err) => {
          console.error("ERROR", err);
          setStreamingOnly(true);
        }
      );
  };

  const submitData = () => {
    const pathName = window.location.pathname;

    axios
      .post("https://livrncnypozlhcydlwad.functions.supabase.co/rsvp", {
        id: pathName.substring(1),
        rsvp: attendance == "yes" ? noOfGuest : 0,
      })
      .then((res) => {
        // console.log(res);
        refreshData();
        setLoading(false);
        setSuccessSubmit(true);
      })
      .catch((err) => {
        setFailedSubmission(true);
        setLoading(false);
      });
  };

  const addToCalendarClick = () => {
    !streamingOnly
      ? window.open(
          "http://www.google.com/calendar/event?action=TEMPLATE&dates=20230114T110000Z%2F20230114T140000Z&text=The%20Wedding%20of%20Alvin%20%26%20Vannia&location=Royal%20Eight%20Chinese%20Semi%20Fine%20Dining&details=The%20pleasure%20of%20your%20company%20is%20requested%20at%20the%20union%20of%20%3Cb%3EAmbrosius%20Alvin%20Gunawan%20%26%20Ignatia%20Vannia%20Ferdina%3C%2Fb%3E.%0A%0A%0A%3Cb%3ERoyal%20Eight%20Chinese%20Dining%3C%2Fb%3E%0AJHL%20Solitaire%20Hotel%0AJl.%20Gading%20Serpong%20Boulevard%2C%20Blok%20S%20No.%205%2C%20Gading%20Serpong%2C%20Tangerang%0A%0A%0AWe%20would%20like%20to%20respectfully%20inform%20you%20that%20%3Ci%3E%3Cb%3Edue%20to%20limited%20seats%2C%20please%20restrict%20the%20attendees%20to%20those%20listed%20on%20your%20invitation%3C%2Fb%3E%3C%2Fi%3E.%0APlease%20arrive%20%3Cb%3E%3Ci%3E30%20minutes%20in%20advance%20for%20the%20registration%20process%3C%2Fi%3E%3C%2Fb%3E."
        )
      : window.open(
          "http://www.google.com/calendar/event?action=TEMPLATE&dates=20230114T020000Z%2F20230114T030000Z&text=The%20Wedding%20of%20Alvin%20%26%20Vannia&location=&details=The%20pleasure%20of%20your%20company%20is%20requested%20at%20the%20union%20of%20%3Cb%3EAmbrosius%20Alvin%20Gunawan%20%26%20Ignatia%20Vannia%20Ferdina%3C%2Fb%3E.%0A%0AJoin%20us%20virtually%20as%20we%20exchange%20wedding%20vows.%20%0ALive%20from%20St.%20Laurensius%20Catholic%20Church%2C%20Tangerang%2C%20%0A%3Cb%3ESaturday%2C%2014%20January%202023%20at%2009.00%20AM%3Cb%3E"
        );
  };
  const img_main = isDesktopOrLaptop ? imageMainLandscape : imageMain;
  const mainBgImg = isDesktopOrLaptop ? bg : bgPhone;
  const guestSection = {
    // fontSize: "18px",
  };

  const attendanceRow = {
    flexDirection: "row",
  };

  return firstLoad ? (
    <div className="loadingPageWrapper">
      <img src={mailGif} style={{ marginBottom: "48px" }} />
      <div>Loading...</div>
    </div>
  ) : (
    <div
      style={isDesktopOrLaptop ? { fontSize: "24px" } : { fontSize: "16px" }}
    >
      {/** AUDIO CONTROL */}
      {isOpen && (
        <div>
          <span style={{ zIndex: "0" }}>
            <img src={outerVinyl} className={"vinyl"} />
          </span>
          <span onClick={onAudioControlClick}>
            <img
              src={innerVinyl}
              className={
                audioPlay
                  ? classNames("vinyl", "playPauseButton", "play")
                  : classNames("vinyl", "playPauseButton")
              }
            />
          </span>
        </div>
      )}
      {/** COVER */}
      <div
        className={
          isDesktopOrLaptop
            ? classNames("firstSection", "firstSectionBgDesktop")
            : classNames("firstSection", "firstSectionBg")
        }
      >
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
        >
          <div style={isDesktopOrLaptop ? { fontSize: "24px" } : {}}>
            THE WEDDING OF
          </div>
          <div
            className="coupleName"
            style={
              isDesktopOrLaptop ? { fontSize: "108px", marginBottom: "0" } : {}
            }
          >
            Alvin & Vannia
          </div>
        </div>
        {isInvited && (
          <div
            data-aos="fade-zoom-in"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="1000"
          >
            <div style={isDesktopOrLaptop ? guestSection : {}}>
              Dear Mr/Ms/Mrs,
            </div>
            <div
              className="guestName"
              style={isDesktopOrLaptop ? guestSection : {}}
            >
              {inviteeDetail && inviteeDetail.name}
            </div>

            <div
              className="invDesc"
              style={isDesktopOrLaptop ? guestSection : {}}
            >
              you are cordially invited to our wedding celebration{" "}
            </div>
          </div>
        )}
        <div
          className="button"
          data-aos="fade-zoom-in"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay="1000"
          onClick={openInvClick}
          style={
            isDesktopOrLaptop
              ? {
                  ...guestSection,
                  padding: "20px",
                  width: "250px",
                  marginBottom: "72px",
                }
              : {}
          }
        >
          <img src={mailIcon} />
          Open Invitation
        </div>
      </div>

      {/** CONTENT */}
      <div
        id="content"
        // style={isOpen ? { display: "block" } : { visibility: "hidden" }}
      >
        <div className="quoteWrapper">
          <div
            className="quote"
            style={
              isDesktopOrLaptop
                ? { fontSize: "48px", width: "700px", paddingTop: "10%" }
                : {}
            }
            data-aos="fade-in"
          >
            <span>
              "I hold you in my heart, for we have shared together God’s
              blessings."
            </span>
            <div
              style={
                isDesktopOrLaptop ? { marginTop: "50px", fontSize: "24px" } : {}
              }
            >
              Philippians 1:7
            </div>
          </div>
        </div>
        <div
          className="groomBride"
          style={{
            backgroundImage: `url(${mainBgImg})`,
          }}
        >
          <div
            data-aos="zoom-in"
            className="intro"
            style={!isTabletOrMobile ? { fontSize: "24px" } : {}}
          >
            By the grace of God, together with their families, the pleasure of
            your company is requested at the union of
          </div>
          <div
            // data-aos="flip-up"
            // data-aos-easing="ease-in-sine"
            // data-aos-delay="200"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div
              className="name"
              style={!isTabletOrMobile ? { fontSize: "64px" } : {}}
            >
              Ambrosius Alvin Gunawan{" "}
            </div>
            {isDesktopOrLaptop ? (
              <div className="family" style={{ fontSize: "20px" }}>
                The second son of Mr. Andi Hardy Gunawan (Ho Po Goan) and Mrs.
                Pinky Gunawan (Kwee Kiong Tjie)
              </div>
            ) : (
              <div className="family">
                <div>The second son of</div>
                <div> Mr. Andi Hardy Gunawan (Ho Po Goan) &</div>
                <div> Mrs. Pinky Gunawan (Kwee Kiong Tjie)</div>
              </div>
            )}
          </div>
          <div
            className="family"
            style={
              isDesktopOrLaptop
                ? { fontSize: "20px", margin: "48px 0px" }
                : { margin: "48px 0px" }
            }
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-bottom"
          >
            and
          </div>
          <div
            // data-aos="flip-up"
            // data-aos-easing="ease-in-sine"
            // data-aos-delay="200"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div
              className="name"
              style={!isTabletOrMobile ? { fontSize: "64px" } : {}}
            >
              Ignatia Vannia Ferdina{" "}
            </div>
            {isDesktopOrLaptop ? (
              <div className="family" style={{ fontSize: "20px" }}>
                The only daughter of Mr. Ferdi Sulaiman (Tjhauw Yung Fong) and
                Mrs. Magdalena Sri Onggosubagjo (Ong Gwat Sioe)
              </div>
            ) : (
              <div className="family" style={{ marginBottom: "36px" }}>
                <div>The only daughter of</div>
                <div>Mr. Ferdi Sulaiman (Tjhauw Yung Fong) &</div>
                <div>Mrs. Magdalena Sri Onggosubagjo (Ong Gwat Sioe)</div>
              </div>
            )}
          </div>
        </div>

        <div
          className="event"
          style={
            isDesktopOrLaptop
              ? { flexDirection: "row" }
              : { flexDirection: "column" }
          }
        >
          {!streamingOnly && (
            <div className="eventDetailWrapper">
              <div
                data-aos="zoom-in-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500"
                className="eventDetail"
                style={
                  isDesktopOrLaptop
                    ? { fontSize: "24px", maxWidth: "500px" }
                    : {}
                }
              >
                <div className={!isTabletOrMobile ? "titleDesktop" : "title"}>
                  Holy Matrimony
                </div>
                <div>Saturday, January 14th, 2023</div>
                <div>09.00 AM</div>
                <div
                  className="place"
                  style={!isTabletOrMobile ? { margin: "36px 0px" } : {}}
                >
                  <div>St. Laurentius Catholic Church</div>
                  <div>Jl. Sutera Utama No. 2, Alam Sutera, Tangerang</div>
                </div>
                <div className={"button"} onClick={openChurchMap}>
                  <img src={mapMarker} />
                  Get Direction
                </div>
              </div>

              <div
                data-aos="zoom-in-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500"
                data-aos-delay="500"
                className="eventDetail"
                style={
                  isDesktopOrLaptop
                    ? {
                        fontSize: "24px",
                        maxWidth: "500px",
                        // marginTop: "64px",
                      }
                    : {}
                }
              >
                <div className={!isTabletOrMobile ? "titleDesktop" : "title"}>
                  Wedding Reception
                </div>
                <div>Saturday, January 14th, 2023</div>
                <div>18.30 PM - 21.00 PM</div>
                <div
                  className="place"
                  style={!isTabletOrMobile ? { margin: "36px 0px" } : {}}
                >
                  <div>Royal Eight Chinese Dining</div>
                  <div>JHL Solitaire Hotel</div>
                  <div>
                    Jl. Gading Serpong Boulevard, Blok S No. 5, Gading Serpong,
                    Tangerang
                  </div>
                </div>
                <div
                  className={classNames("button", "direction")}
                  onClick={openHotelMap}
                >
                  <img src={mapMarker} />
                  Get Direction
                </div>
              </div>
            </div>
          )}
          {/**LIVE STREAMING */}
          <div
            className="liveStreamingWrapper"
            style={
              isDesktopOrLaptop ? { fontSize: "24px", maxWidth: "500px" } : {}
            }
          >
            <div
              className={!isTabletOrMobile ? "titleDesktop" : "title"}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="3000"
            >
              Holy Matrimony Live Streaming
            </div>
            {/* <img src={lineDivider} /> */}
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="3000"
              className="place"
              style={!isTabletOrMobile ? { margin: "36px 0px" } : {}}
            >
              <div>
                Join us virtually as we exchange wedding vows. Live from St.
                Laurensius Catholic Church, Tangerang,{" "}
                <b>Saturday, 14 January 2023 at 09.00 AM</b>
              </div>
            </div>
            <div className="videoWrapper" data-aos="zoom-out-up">
              <iframe
                src="https://www.youtube.com/embed/WTz6KplcfMM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        {/* RSVP */}
        {!streamingOnly && (
          <div
            className="rsvp"
            style={
              isDesktopOrLaptop
                ? {
                    flexDirection: "row",
                    paddingTop: "72px",
                  }
                : { flexDirection: "column" }
            }
          >
            <img src={rsvp} className="rsvpImg" alt="" data-aos="flip-up" />

            <div
              style={
                isDesktopOrLaptop ? { maxWidth: "50%", marginLeft: "20px" } : {}
              }
            >
              <div data-aos="fade-in" data-aos-delay="300">
                <div>
                  <div>
                    With an abundance of care for our guests, we have decided to
                    celebrate our wedding in an intimate sit-down dinner with
                    our immediate families. We would like to respectfully inform
                    you that{" "}
                    <i style={{ fontWeight: "bold" }}>
                      due to limited seats, please restrict the attendees to
                      those listed on your invitation.
                    </i>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    Please arrive{" "}
                    <i style={{ fontWeight: "bold" }}>30 minutes in advance</i>{" "}
                    for registration process
                  </div>
                </div>
                <hr style={{ margin: "24px 0px" }} />
                <div>
                  <div>
                    Kami memutuskan untuk merayakan pernikahan kami dalam jamuan
                    makan malam terbatas demi kenyamanan bersama. Dengan segala
                    hormat, kami menginformasikan bahwa{" "}
                    <i style={{ fontWeight: "bold" }}>
                      karena keterbatasan kursi, harap datang sesuai dengan
                      jumlah dan nama yang tertera pada undangan.
                    </i>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    Harap hadir{" "}
                    <i style={{ fontWeight: "bold" }}>30 menit lebih awal</i>{" "}
                    untuk registrasi.
                  </div>
                </div>
              </div>
              <div data-aos="fade-in">
                <div className="rsvpFormSection">
                  {!successSubmit && inviteeDetail.rsvp == null ? (
                    <div style={{ marginBottom: "20px" }}>
                      We look forward to celebrating with you. Please confirm
                      your attendance by
                      <i style={{ fontWeight: "bold" }}> {dueStr}</i>.
                    </div>
                  ) : (
                    <div style={{ marginBottom: "20px", fontWeight: "bold" }}>
                      {inviteeDetail.rsvp > 0
                        ? `Thank you for confirming your attendance. We have reserved ${inviteeDetail.rsvp} seats in your honor. We look forward to celebrating with you.`
                        : "Thank you for responding to our invitation. Your presence will be sorely missed."}
                    </div>
                  )}
                  <div></div>

                  {inviteeDetail.rsvp == null && (
                    <div
                      className="attRow"
                      style={isDesktopOrLaptop ? attendanceRow : {}}
                    >
                      <div style={isDesktopOrLaptop ? { maxWidth: "30%" } : {}}>
                        Will you attend?
                      </div>
                      <div
                        style={isTabletOrMobile ? { marginTop: "12px" } : {}}
                        onChange={onAttendanceChange}
                      >
                        <label
                          className="container"
                          style={successSubmit ? { cursor: "not-allowed" } : {}}
                        >
                          Yes
                          <input
                            type="radio"
                            name="radio"
                            value="yes"
                            disabled={inviteeDetail.rsvp != null}
                            defaultChecked={
                              inviteeDetail.rsvp && inviteeDetail.rsvp != 0
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label
                          className="container"
                          style={successSubmit ? { cursor: "not-allowed" } : {}}
                        >
                          No
                          <input
                            type="radio"
                            name="radio"
                            value="no"
                            disabled={inviteeDetail.rsvp != null}
                            defaultChecked={
                              inviteeDetail.rsvp != null &&
                              inviteeDetail.rsvp == 0
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  )}
                  {showNoOfGuest && (
                    <div
                      className="attRow"
                      style={isDesktopOrLaptop ? attendanceRow : {}}
                    >
                      <div style={isDesktopOrLaptop ? { maxWidth: "30%" } : {}}>
                        No of Guest
                      </div>
                      <div
                        style={isTabletOrMobile ? { marginTop: "12px" } : {}}
                      >
                        <select
                          id="noOfGuest"
                          name="noOfGuest"
                          style={{ width: "200px" }}
                          defaultValue={
                            inviteeDetail.rsvp == null
                              ? "opt"
                              : inviteeDetail.rsvp
                          }
                          onChange={onNoOfGuestChange}
                          disabled={inviteeDetail.rsvp != null}
                        >
                          {}
                          <option disabled value="opt">
                            {" "}
                            -- select an option --{" "}
                          </option>
                          {generateSeatOption()}
                        </select>
                      </div>
                    </div>
                  )}

                  {formErr && (
                    <div className="errMsg">
                      <i>Please fill out the form prior submitting</i>
                    </div>
                  )}
                  {failedSubmission && (
                    <div className="errMsg">
                      <i>
                        There's an error while contacting server. Please try
                        again.
                      </i>
                    </div>
                  )}
                  {inviteeDetail.rsvp == null && (
                    <div className="submitBtn" onClick={onSubmitClick}>
                      Submit
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/**GALLERY */}
        <div>
          <PhotoAlbum photos={photos} layout="columns" />
        </div>
        {/**PROTOCOL */}
        {!streamingOnly && (
          <div className="protocolContainer">
            <div
              className={!isTabletOrMobile ? "titleDesktop" : "title"}
              data-aos="fade-in"
            >
              Health Protocol
            </div>
            <div style={{ fontWeight: "bold" }} data-aos="fade-in">
              The health of each of you and your loved ones are what matters
              most. We wanted to share important Covid-19 protocols we will be
              following during the event.
            </div>
            <div
              className="protocolItemWrapper"
              style={isDesktopOrLaptop ? {} : { flexDirection: "column" }}
              data-aos="fade-in"
            >
              <div style={isDesktopOrLaptop ? { marginTop: "26px" } : {}}>
                <div className="protocolItem">
                  <img src={mask} /> Must use masks during the event
                </div>
                <div className="protocolItem">
                  <img src={distance} /> Kindly keep your social distance
                </div>
              </div>
              <div
                style={
                  isDesktopOrLaptop
                    ? { marginTop: "26px" }
                    : { marginTop: "32px" }
                }
              >
                <div className="protocolItem">
                  <img src={soap} />
                  Wash hands and use hand sanitizer
                </div>
                <div className="protocolItem">
                  <img src={pedulilindungi} />
                  Check in using the PeduliLindungi App
                </div>
              </div>
            </div>
          </div>
        )}
        {/**COUNTDOWN */}
        <div
          className="countdownContainer"
          style={
            isDesktopOrLaptop
              ? {
                  height: "calc(100vh - 60px)",
                }
              : {}
          }
        >
          <div
            className={!isTabletOrMobile ? "titleDesktop" : "title"}
            data-aos="fade-in"
          >
            Counting Days
          </div>
          <div className="timerWrapper" data-aos="flip-up">
            <div
              className={
                isDesktopOrLaptop ? "countdownBox-desktop" : "countdownBox"
              }
            >
              <div
                className={
                  isDesktopOrLaptop
                    ? "countdownNumber-desktop"
                    : "countdownNumber"
                }
              >
                {days.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </div>{" "}
              days
            </div>
            <div
              className={
                isDesktopOrLaptop ? "countdownBox-desktop" : "countdownBox"
              }
            >
              <div
                className={
                  isDesktopOrLaptop
                    ? "countdownNumber-desktop"
                    : "countdownNumber"
                }
              >
                {hours.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </div>{" "}
              hours
            </div>
            <div
              className={
                isDesktopOrLaptop ? "countdownBox-desktop" : "countdownBox"
              }
            >
              <div
                className={
                  isDesktopOrLaptop
                    ? "countdownNumber-desktop"
                    : "countdownNumber"
                }
              >
                {minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </div>{" "}
              minutes
            </div>
            <div
              className={
                isDesktopOrLaptop ? "countdownBox-desktop" : "countdownBox"
              }
            >
              <div
                className={
                  isDesktopOrLaptop
                    ? "countdownNumber-desktop"
                    : "countdownNumber"
                }
              >
                {seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </div>{" "}
              seconds
            </div>
          </div>
          <div
            className="button"
            style={{ marginTop: "48px" }}
            onClick={addToCalendarClick}
            data-aos="fade-in"
          >
            <img src={calendar} />
            Add to Calendar
          </div>
        </div>
      </div>
      {loading && (
        <div className="loadingOverlay">
          <div className="loadingContainer">
            <div style={{ marginBottom: "8px" }}>
              <img src={loadingImg} alt="" />
            </div>
            Please wait
          </div>
        </div>
      )}
    </div>
  );
}
